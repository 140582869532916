<template lang="pug">
div(v-show="!overlay_full")
  v-app
    v-navigation-drawer(v-model="navDrawerIsOpen" theme="dark" permanent  rail width="55")
      v-list-item.ml-2.mt-2(nav, prepend-avatar='/images/RELOWCODE-white-bars.png')
      v-divider
      v-list(density='compact', nav)
        v-list-item(prepend-icon='clarity:home-solid', value='home'  to="/app/" v-tooltip:end="'Home'")
        v-list-item(v-if="route.params.id" prepend-icon='ic:round-settings', value='settings' :to="'/app/app-'+route.params.id+'/general-'" v-tooltip:end="'Settings'")
        v-list-item(v-if="route.params.id" prepend-icon='material-symbols:database', value='database' :to="'/app/app-'+route.params.id+'/tables'" v-tooltip:end="'DB'")
        v-list-item(v-if="route.params.id" prepend-icon='entypo:round-brush', value='classes' :to="'/app/app-'+route.params.id+'/classes'" v-tooltip:end="'Styles'")
        v-list-item(v-if="route.params.id" prepend-icon='fa-solid:edit', value='editor' :to="'/app/app-'+route.params.id+'/editor'" v-tooltip:end="'Editor'")
        v-list-item(v-if="route.params.id" prepend-icon='mingcute:upload-line', value='publish' :to="'/app/app-'+route.params.id+'/publish'" v-tooltip:end="'Publish'")
        v-list-item(prepend-icon='clarity:user-solid' value="myaccount" to="/app/myaccount" v-tooltip:end="'Profile'")
      
        
      template(v-slot:append)    
        v-list-item(prepend-icon='clarity:logout-line', value='logout' @click="signOut" v-tooltip:end="'Logout'")


    
    v-main.pt-3.bg-white
      slot.showvvar
div(v-show="overlay_full")
  v-container 
    v-row.h-screen(align="center" justify="center" )
      v-col(cols=8 lg=6  justify="center")
        v-row(align="center" justify="center")
          v-col(justify="center").text-center
            img(src="/images/ReLowCode_Logo.png" height="150px")
        v-row
          v-col.text-center
            v-progress-circular(indeterminate :size="42")
            br
            br
            span loading
</template>
<script setup>
const {signOut} = useAuth()
const showApps = ref(false)
const usingEditor = ref(false)
const usingSettings = ref(false)
const navDrawerIsOpen = ref(true)

function showAllAppsEditor(){
  showApps.value = true
  usingEditor.value = true
}

const route = useRoute()





const overlay_full = ref(true)
onMounted(() => {
  setTimeout(() => {
    overlay_full.value = false
  }, 500)
  
})



</script>

<style lang="sass">
.showvvar
  overflow-y: scroll
</style>